import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { BlobServiceClient } from '@azure/storage-blob';
import { funcAppScope } from '../../auth-config';
import { saveAs } from 'file-saver';

const useAzureBlobs = () => {

    const getSASKey = useCallback(async (service = "Diane") => {
        var payload = {
            token: "",
            function: "authSAS",
            service: service,
        };

        let response = await axios({
            method: "POST",
            headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "text/plain",
            },
            data: JSON.stringify(payload),
            url: "/api/credentials",
            timeout: 0,
        });
        let message = await response.data;
        let sasKey = message["sasKey"];
        let url = message["url"];
        let account_name = message["account_name"];

        return {
            key: sasKey,
            url: url,
            account_name: account_name,
        };
    }, []);

    const getBlobServiceClient = useCallback(async (service = "Diane") => {
        let sas = await getSASKey(service);
        return new BlobServiceClient(
            `https://${sas["account_name"]}.blob.core.windows.net?${sas["key"]}`
        );
    }, [getSASKey]);

    const uploadString = useCallback(async (containerName, blobName, content, service = "Diane") => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const uploadBlobResponse = await blockBlobClient.upload(
            content,
            content.length
        );
        console.log(
            `Upload block blob ${blobName} successfully`,
            uploadBlobResponse.requestId
        );
    }, [getBlobServiceClient]);

    const uploadFile = useCallback(async (containerName, blobName, file, service) => {
        try
        {
            const blobServiceClient = await getBlobServiceClient(service);
            const containerClient = blobServiceClient.getContainerClient(containerName);

            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            const result = await blockBlobClient.uploadData(file);
            console.log(`Upload block blob ${blobName} successfully`, result);
        }
        catch (error)
        {
            console.error(error);
        }
    }, [getBlobServiceClient]);

    const uploadFiles = useCallback(async (containerName, blobNames, files, service) => {
        const uploadPromises = files.map((file, i) => {
            const blobName = blobNames[i];
            return uploadFile(containerName, blobName, file, service);
        });
        await Promise.all(uploadPromises);
    }, [uploadFile]);

    const listBlobs = useCallback(async (containerName, service = "Diane") => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);

        let i = 1;
        let blobs = containerClient.listBlobsFlat();
        let result = [];
        for await (const blob of blobs) {
            result.push(blob);
        }

        return result;
    }, [getBlobServiceClient]);

    const downloadBlob = useCallback(async (containerName, blobName, fileName, service) => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        const downloadBlockBlobResponse = await blobClient.download();
        let data = await downloadBlockBlobResponse.blobBody;
        saveAs(data, fileName);
        console.log(`${fileName} downloaded`);
    }, [getBlobServiceClient]);

    const deleteBlob = useCallback(async (containerName, blobName, service) => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        const response = await blobClient.deleteIfExists();
    }, [getBlobServiceClient]);

    const readBlobImage = useCallback(async (containerName, blobName, service = "Diane") => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        const downloadBlockBlobResponse = await blobClient.download();
        let data = await downloadBlockBlobResponse.blobBody;
        var url = window.URL || window.webkitURL;
        var img_url = url.createObjectURL(data);
        return img_url;
    }, [getBlobServiceClient]);

    const readBlob = useCallback(async (containerName, blobName, service = "Diane") => {
        const blobServiceClient = await getBlobServiceClient(service);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);

        // Get blob content from position 0 to the end
        const downloadBlockBlobResponse = await blobClient.download();
        const downloaded = await blobToString(
            await downloadBlockBlobResponse.blobBody
        );

        // A helper method used to convert a browser Blob into string.
        async function blobToString(blob) {
            const fileReader = new FileReader();
            return new Promise((resolve, reject) => {
            fileReader.onloadend = (ev) => {
                resolve(ev.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(blob);
            });
        }
        return downloaded;
    }, [getBlobServiceClient]);
  
    return {
        getSASKey,
        getBlobServiceClient,
        uploadString,
        uploadFiles,
        uploadFile,
        listBlobs,
        downloadBlob,
        deleteBlob,
        readBlobImage,
        readBlob
    };
};

export default useAzureBlobs;


