import { useEffect, useState, useMemo } from 'react';

import useAzureFuncApp from './azure/useAzureFuncApp';
import { useEntraUser } from '../EntraUserProvider';
import config from '../config';

const useTools = (searchName) => {
  const { axiosClient } = useAzureFuncApp();
  const { getGraphToken } = useEntraUser();
  
  // Local state
  const [tools, setTools] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchTools = async () => {
      setLoading(true);
      setError(false);

      try {
      const accessToken = await getGraphToken();
      const payload = { accessToken: accessToken, is_debug: config.isDebug };
      const response = await axiosClient.post(
        config.apiToolsUri, payload,
//        { headers: { 'Cache-Control': 'max-age=3600' } }
      );

      if (response.status === 200) {
        const data = response.data;
        setTools({
          Plan: data.ToolDiane,
          PDF: data.ToolDevis,
          Tech: [],
        });
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
    };

    fetchTools();
  }, []);

  const filterAndSortTools = (toolsArray) => {
    if (!toolsArray) return [];
    const unorderedTools = toolsArray.filter((x) =>
      x.name.toLowerCase().includes(searchName.toLowerCase())
    );
    unorderedTools.sort((a, b) => ((a.isfavorite && !b.isfavorite) ? -1 : 1));
    return unorderedTools;
  };

  const filteredPlanServices = useMemo(() => filterAndSortTools(tools?.Plan), [tools, searchName]);
  const filteredPdfServices = useMemo(() => filterAndSortTools(tools?.PDF), [tools, searchName]);
  const filteredDevisServices = useMemo(() => filterAndSortTools(tools?.Tech), [tools, searchName]);

  const favoriteServices = useMemo(() => {
    if (!tools) return [];
    const unorderedTools = [...tools.Plan, ...tools.PDF, ...tools.Tech];
    unorderedTools.sort((a, b) => (!b.isfavorite && a.isfavorite ? -1 : 1));
    return unorderedTools.slice(0, 4);
  }, [tools]);

  return {
    loading,
    error,
    filteredPlanServices,
    filteredPdfServices,
    filteredDevisServices,
    favoriteServices,
  };
};

export default useTools;
