const environment = process.env.REACT_APP_ENVIRONMENT.trim();

const API_DENOMBREMENT_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DENOMBREMENT_URI : process.env.REACT_APP_API_DENOMBREMENT_URI_TEST;
const API_DIANE_INTERNAL_URI    = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_INTERNAL_URI : process.env.REACT_APP_API_DIANE_INTERNAL_URI_TEST;
const API_DIANE_PLATEFORME_URI  = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_PLATEFORME_URI : process.env.REACT_APP_API_DIANE_PLATEFORME_URI_TEST;
const API_PYM_URI               = environment === "production" || environment === "local" ? process.env.REACT_APP_API_PYM_URI : process.env.REACT_APP_API_PYM_URI_TEST;
const API_OMIA_URI              = environment === "production" || environment === "local" ? process.env.REACT_APP_API_OMIA_URI : process.env.REACT_APP_API_OMIA_URI_TEST;
const API_ALLYSIA_URI           = environment === "production" || environment === "local" ? process.env.REACT_APP_API_ALLYSIA_URI : process.env.REACT_APP_API_ALLYSIA_URI_TEST;
const API_DATAQUALITY_URI       = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DATAQUALITY_URI : process.env.REACT_APP_API_DATAQUALITY_URI_TEST;
const API_INTELIA_URI           = environment === "production" || environment === "local" ? process.env.REACT_APP_API_INTELIA_URI : process.env.REACT_APP_API_INTELIA_URI_TEST;
const API_SEGMENTATION_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_SEGMENTATION_URI : process.env.REACT_APP_API_SEGMENTATION_URI_TEST;

const config = {
  isLocal: environment === "local",
  isDebug: environment === "local" || environment === "development",

  apiBlobManagementUri: "/api/blobs",
  apiCredentialsUri: "/api/credentials",
  apiErrorReportUri: "/api/errorreport",
  apiAccessUri:"/api/page_access",
  apiToolsUri:"/api/gettools",
  apiGetRolesUri:"/api/getroles",
  apiFileUrlUri:"/api/getblobfileurl",

  apiCountItemsUri: `${API_DENOMBREMENT_URI}/CountItems`,
  apiUxellOGSUri: `${API_DENOMBREMENT_URI}/Fabecrea`,
  apiMetreUri: `${API_DENOMBREMENT_URI}/Metre`,
  
  apiContracts: `${API_DIANE_INTERNAL_URI}/contrats`,
  get_data:`${API_DIANE_INTERNAL_URI}/get_data`,
  apiGitHubActivity: `${API_DIANE_INTERNAL_URI}/business_github_activity`,
  
  apiApproUxelloUri: `${API_DIANE_PLATEFORME_URI}/appro`,
  apiC2CUri: `${API_DIANE_PLATEFORME_URI}/c2c`,
  apiChatbotUri: `${API_DIANE_PLATEFORME_URI}/Chatbot`,
  apiDifferenceIndexUri: `${API_DIANE_PLATEFORME_URI}/DifferenceIndexBlueprint`,
  apiElioveUri: `${API_DIANE_PLATEFORME_URI}/eliove`,
  apiPDF2RevitUri: `${API_DIANE_PLATEFORME_URI}/Pdf2Revit`,
  apiPdfTextDifferenceUri: `${API_DIANE_PLATEFORME_URI}/PdfTextDifference`,
  apiPdfUri: `${API_DIANE_PLATEFORME_URI}/Pdf`,
  apiVtidf: `${API_DIANE_PLATEFORME_URI}/Vtidf`,
  apiClauseControl: `${API_DIANE_PLATEFORME_URI}/ClauseControl`,
  apiSprinklerDB : `${API_DIANE_PLATEFORME_URI}/SprinklerDB`,
  
  apiContactUri: `${API_DIANE_PLATEFORME_URI}/Contact`,
  apiFeedbackUri: `${API_DIANE_PLATEFORME_URI}/Feedback`,
  apiWhatsNextUri: `${API_DIANE_PLATEFORME_URI}/WhatsNext`,
  
  pymParseFile: `${API_PYM_URI}/parse_files`,
  pymGetLights: `${API_PYM_URI}/parse_files`,
  pymLoadSave: `${API_PYM_URI}/load_save`,
  pymDeleteSave: `${API_PYM_URI}/delete_save`,
  pymSimulation: `${API_PYM_URI}/simulation`,
  pymOptimisation: `${API_PYM_URI}/optimisation`,
  apiPymUri: `${API_PYM_URI}`,

  apiAllysiaUri: `${API_ALLYSIA_URI}/`,

  apiDataQualityUri: `${API_DATAQUALITY_URI}`,
  apiInteliaUri: `${API_INTELIA_URI}`,

  apiVfMaintenance: `${API_OMIA_URI}/VFOffreMaintenance`,
  
  apiSegmentationUri: `${API_SEGMENTATION_URI}/fastsam_count`,
  apiDockerPdfUri: `${API_SEGMENTATION_URI}/PDF`,
};

export const helpUrls = {
  Allysia: undefined,
  Intelia: undefined,
  ClauseControl: "https://www.youtube.com/embed/dupD19V_VNo?si=JgGNmaY6coFqRlKj",
  ComparaisonPlan: undefined,
  ComparaisonTexte: undefined,
  Comptage: [{
    title: "Création de projet",
    url: "https://www.youtube.com/embed/dJakX1Px3pk?si=NIZtKgYI-lTIsmmj"
  }, {
    title: "Paramétrage",
    url: "https://www.youtube.com/embed/Si0HxIolw4w?si=A92wojVSroDgfF14"
  }, {
    title: "Dénombrement",
    url: "https://www.youtube.com/embed/h7nal_alfD0?si=hEhzCsVdL9jN4ZVz"
  }, {
    title: "Métré",
    url: "https://www.youtube.com/embed/bMec5j9CmY0?si=P2cN8HihrPjZSzDF"
  }],
  Contractualisation: undefined,
  OMIA: "https://www.youtube.com/embed/wG2QVc2Okhc?si=fx0BBUwa1MMuDyqL",
  PDF2Revit: undefined,
  PYM: [{
    title: "Initialisation",
    url: "https://www.youtube.com/embed/lOvuBmkPDGw?si=pa4C_koBs8E8Uqi5"
  }, {
    title: "Simulation",
    url: "https://www.youtube.com/embed/xoppDSA5N54?si=D0aYkALvkDHolPtR"
  }, {
    title: "Tronçon type",
    url: "https://www.youtube.com/embed/hcl9uJHFKso?si=KAKt7QTSH1Qwk2sX"
  }, {
    title: "Paramètres",
    url: "https://www.youtube.com/embed/bdOXZhql9aw?si=_iC8qTMPsMvyKNON"
  }],
  UxelloAppro: "https://www.youtube.com/embed/4VhMynZN5SM?si=juLX0ke-p9nTn2yq",
};

export default config;
